<template>
  <div class="container">

    <div class="row">
      <div class="col flags">
        <img src="@/assets/images/italy.png" @click="goto('ita')">
        <img src="@/assets/images/usa.png" @click="goto('eng')" />
      </div>
    </div>
    <div class="row">
      <div class="col card">
        <div class="row">
          <div class="col-7 text-left" v-if="eng">
            <h6>What is S-LCA?</h6>
            <p><b>Social Life Cycle Assessment</b> is a method to understand what the social hotspots of a value chain are, that are the activities, taking place in a specific location, that may correspond to a problem, a risk or an opportunity, in relation to a social theme of interest. S-LCA follows the ISO 14040 framework, the Guidelines for Social Life Cycle Assessment of Products developed by UNEP, the Handbook for Product Social Impact Assessment version 3.0 by the Roundtable for product social metrics, and other<b> references</b>. Basically, first the value chain is identified setting boundaries (what are the activities included in the analysis and what is cutted-off?). Then <b> primary data are collected </b> (Life Cycle Inventory) to assess specific indicators, related to some selected <b> social topics </b>.  Finally, the data are elaborated to offer "<b>food for thought</b>" for the intended audience. It can be helpful to be aware of the most sensitive unit processes and locations, as starting point for monitoring and improvements.</p>
            <h6>How to use this tool?</h6>
            <p>Move among the different <b> stakeholders </b>clicking on the vertical tabs on the left. For each stakeholder you will find some <b> social topics </b> with some questions: answer all of them. Click on the Social Topic to change section. Rollover on the “<b> info </b>” icon to read some further instructions (e.g. which department or stakeholders is suggested to be interviewed to get the answers). Comment on the “<b> note </b>” box to make us now some impressions, assumptions, doubts. Mark the “<b> low data quality </b>” check box if you do not feel confident with your answers. You can <b> Save </b> the questionnaire and fill it in at a later stage.  When the questionnaire is completed, <b> Send it to Admin! </b>  Please, collect the answers regarding your organization, and in particular the plant or location in which the activities for the <b> Project </b> are carried out.  When the questions refer to a “product”, please refer to the output that the project require you to produce.  Consider the status at the present moment, or when the question regards a period of time, the last five years indicatively.</p>

            <p>It is well known that some of the questions, could be answered differently when asked to a person, rather than another. For each question we suggest who the most <b> suitable role/people to answer </b> may be. Sometimes the best approach to provide an accurate answer is to collect more than one opinion, and to consider both the ones coming from the formal responsible of the topics and the informal and transparent ones. Please write in the Notes any comment you believe could help the analysis, and reference to the person/role that helped answering the question.  For some answers, you can refer to the <b> Sustainability Report </b> (Non-Financial Declaration) of the company, when available.  Last but not least... <b> thank you very much for the collaboration!</b></p>
          </div>
          <div class="col-7 text-left" v-if="!eng">
            <h6>Cosa si intende per S-LCA?</h6>
            <p>Il Social Life Cycle Assessment è una <b> valutazione di impatto sociale. </b> È una metodologia per capire quali sono i “punti caldi” dal punto di vista sociale di una certa filiera. Questi sono attività che hanno luogo in uno specifico ambiente, che possono corrispondere ad un problema, un rischio o un’opportunità, in relazione ad una certa tematica sociale.  S-LCA segue la ISO 14040, le linee guida dettate dal programma delle Nazioni Unite per l’ambiente, e altri <b> documenti di riferimento. </b>  Fondamentalmente, prima la filiera di riferimento viene identificata tracciando dei confini (quali attività vengono incluse nell’analisi e quali no?). Poi dei <b> dati primari sono raccolti </b> (LCI) per valutare specifici indicatori riferiti a delle <b> tematiche sociali. </b> Infine, i dati vengono elaborati per offrire <b> spunti di riflessione </b> al pubblico di riferimento. Può essere prezioso per realizzare quali sono gli aspetti, le location e i processi più delicati, come punto di partenza per monitoraggio e miglioramenti.</p>
            <h6>Come usare questa piattaforma?</h6>
            <p>Entra nel questionario. Muoviti tra le diverse categorie di <b> stakeholders </b> cliccando nelle sezioni verticali a sinistra. Per ogni stakeholder troverai alcune tematiche sociali <b> (social topics) </b> con delle domande: rispondi ad ognuna di esse.  Clicca sul nome della tematica sociale per cambiare sezione.  Passando con il cursore sopra l’icona “<b> info </b>”, potrai leggere alcune istruzioni o consigli per rispondere.  Commenta nello spazio destinato alle note per farci sapere le tue impressioni, assunzioni, dubbi.  Clicca sulla spunta “<b> low data quality </b>” se non ti senti sicuro delle tue risposte.  Puoi salvare il questionario <b> (SAVE) </b> e completarlo in un altro momento. Quando è completo, inviacelo <b> (SEND IT TO ADMIN). </b></p>

            <p>Gentilmente, raccogli le risposte riguardo all atua organizzazione, ed in particolare allo stabilimento o realtà in cui sono svolte le attività per il progetto.  Quando le domande si riferiscono ad un prodotto, riferisciti all’output prodotto per il <b> Progetto. </b>  Considera lo stato attuale, o comunque un periodo che ricopre indicativamente gli ultimi cinque anni.  Certe domande possono avere risposte diverse a seconda di chi viene interpellato, suggeriamo quindi di rivolgersi a più persone e ruoli aziendali ed affidarsi sia alle risposte formali del reposnabile ufficiale di una certa tematica, che a quelle più informali e spontanee di persone coinvolte nell’argomento.  Per certe domande, puoi fare riferimento al <b> Report di Sostenibilità</b>, se disponibile per la tua organizzazione.  Infine, ma non meno importante... <b>grazie mille per la tua collaborazione!</b></p>
          </div>
          <div class="col-5 text-left">
            <img class="home-img" src="@/assets/homeuser.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-right padtop"  v-if="one">
        <router-link :to="'/compile/' + $store.state.user.projects[0].id" class="btn btn-success">Go to the questionnaire</router-link>
      </div>
       <div class="col text-right padtop" v-if="!one">
         <router-link to="/chooseproject" class="btn btn-success">Go to the questionnaire</router-link>
       </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userhome',
  data: function () {
    return {
      eng: true,
      one: (this.$store.state.user.projects.length == 1) ? true : false
    }
  },
  methods: {
    goto (lang) {
      this.eng = lang == 'eng' ? true : false
    }
  }
}
</script>

<style scoped>
.card {
  padding: 20px;
}
h6 {
  font-weight: bold !important;
}

p {
  font-size: 14px;
  line-height: 180%;
}

.btn-success {
  background-color: #136127;
}

.col.padtop {
  padding: 20px 0 0 0;
}

.flags {
  text-align: right;
}

.flags img {
  display: inline-block;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 15px;
}

.home-img{
  height: auto;
  width: 100% ;
}
</style>
